const fr = {
    aftersales:{
        "contactus":"Contactez-nous pour une réponse rapide",
        "workorder":"Liste des bons de travail",
        "createjob":"Créer un emploi",
        "submittinworkorder":"Lorsque vous soumettez un bon de travail, veuillez inclure autant d'informations détaillées que possible, telles que des captures d'écran d'erreur, des informations de compte, etc., qui sont très importantes pour que nous puissions traiter les problèmes rapidement.",
        "workordercontent":"Contenu du bon de travail",
        "workorderreply":"Réponse à l'ordre de travail",
        "subscriptionaccount":"Compte d'abonnement",
        "problemtype":"Type de problème",
        "tobeprocessed":"À traiter",
        "reply":"répondre",
        "processed":"Traité, voir les réponses",
        "revoked":"Révoqué",
        "create":"Créer",
        "workorderno":"Ordre de travail n°",
        "product":"Produit",
        "status":"Statut",
        "type":"Taper",
        "ticket":"Billet",
        "createtime":"créer du temps",
    },
    excode:{
        "redemptioncode":"Utiliser le code",
        "pleaseredemptioncode":"Veuillez saisir le code d'échange",
        "exchange":"Utiliser",
        "notused":"non utilisé",
        "used":"Utilisé",
        "expired":"Expiré",
        "status":"Statut",
        "exchangetime":"Date d'échange",
        "remark":"Remarque",
        "createtime":"créer du temps",
    },
    footer:{
        "contactus":"Contactez-nous",
        "navigation":"Navigation",
        "home":"Maison",
        "friends":"Amis",
        "subscribe":"S'abonner",
        "aftersales":"Service après-vente",
        "help":"Aide",
        "termsofuse":"Conditions d'utilisation",
        "refundpolicy":"Politique de remboursement",
        "privacypolicy":"politique de confidentialité",
        "helpcenter":"centre d'aide",
        "paymentmethod":"Mode de paiement",
        "language":"Langue",
    },
    friends:{
        "promotioninfo":"Informations promotionnelles",
        "currentlevel":"niveau actuel",
        "content":"Vous pouvez utiliser un lien d'invitation exclusif et des codes de réduction à partager avec d'autres pour gagner des commissions. Plus le niveau est élevé, plus la part de commission est élevée",
        "rolemembers":"Le rôle des membres",
        "promotionallinks":"Lien d'invitation",
        "discountcodes":"Codes de réduction",
        "copy":"copie",
        "rebateratio":"Taux de commission",
        "discountratio":"Taux d'actualisation",
        "lowestdiscountratio":"Remise sur l'abonnement utilisateur",
        "walletsandpoints":"Portefeuilles et montant gelé",
        "applywithdrawal":"Après avoir demandé un retrait, nous examinerons votre demande et effectuerons le paiement dans les 7 jours ouvrables.",
        "withdraw":"Retirer",
        "record":"Enregistrer",
        "integral":"Intégral",
        "wallet":"Portefeuilles",
        "freezeamount":"Geler",
        "pointsused":"Les produits YouTube sont bloqués pendant 3 jours, les autres produits sont bloqués pendant 1 jour",
        "orderdetails":"Détails de la commande",
        "orderamount":"Montant de la commande",
        "earnings":"Gains",
        "withdrawalsrecord":"Registre des retraits",
        "accounts":"Comptes",
        "accounttype":"Type de compte",
        "withdrawalamount":"Montant",
        "cancel":"Annuler",
        "submit":"Soumettre",

        "greaterthan":"Le montant doit être supérieur à 0 !",
        "normal":"normale",
        "invalid":"invalide",
        "freeze":"Geler",
        "withdrawing":"Retrait",
        "extracted":"Extrait",

        "bankcard":"carte bancaire",
        "promotionlink":"Lien promotionnel",
        "discountcode":"Code de réduction",
        "waitingforapproval":"En attente d'approbation",
        "waitingforpayment":"En attente de paiement",
        "notpass":"ne passe pas",
        "paid":"Payé",

        "cashwithdrawalid":"ID de retrait d'espèces",
        "accountsreceivable":"Comptes débiteurs",
        "amount":"Montant (USD)",
        "status":"statut",
        "applicationtime":"Délai de candidature",
        "processingresults":"Traitement des résultats",

        "orderid":"ID de commande",
        "useramount":"Montant de l'utilisateur",
        "duration":"durée",
        "promotiontype":"type de promotion",
        "createtime":"créer du temps",
        "eainings":"Bénéfices (USD)",

        "receivingaccount":"compte de réception",
        "nowithdrawableamount":"Retrait minimum de 30 USD",
        "copysuccessfully":"Copier avec succès",
        "copyfailed":"La copie a échoué",
        "activetime":"Temps d'activité",
    },
    home:{
        'join':'Rejoindre',
        "texttitle":"Abonnement premium partagé avec un prix réduit sur",
        "buynow":"ACHETEZ MAINTENANT",
        "soldout":"RUPTURE DE STOCK",
        "shoptitle":"Après avoir passé la commande, vérifiez le message électronique de l'e-mail de connexion actuel (veuillez vous assurer que l'adresse e-mail est correcte)",
        "delivery":"LIVRAISON EN TEMPS RÉEL",
        "after":"Livraison en temps réel après paiement sans attente",
        "certificate":"CERTIFICAT SSL",
        "payments":"Les paiements s'effectuent dans un environnement sécurisé avec un certificat de sécurité SSL",
        "onlinesupport":"ASSISTANCE EN DIRECT 24 h/24 et 7 j/7",
        "privatecustomer":"Nfxbus fournit un service client privé en ligne 24h/24 et 7j/7",
        "subscriber":"CO-ABONNE APPROPRIÉ",
        "subscriptions":"Abonnement premium partagé à prix réduit. Correspondances prioritaires pour les co-abonnés du même pays",
        "moneyback":"GARANTIE DE REMBOURSEMENT",
        "refund":"Vous avez 1 à 3 jours pour demander un remboursement (YouTube 3 jours Autres produits 24 heures)",
        "pwdreset":"Mot de passe de réinitialisation rapide",
        "pwdresetdesc":"Le mot de passe ne fonctionne pas ? Cliquez sur Réinitialiser le mot de passe sur la page d'abonnement sans attendre ni opération manuelle",
        "parkingspace":"place de parking",
        "gift":"cadeau",
        "screen":"Écran",
        "1screen":"1 profil (partagé)",
        "maxnum":"profils (privé)",
        "month":"Mois",
        "buytyp":"Type d'achat",
        "subscription":"abonnement",
        "voucher":"Bon d'achat (renouvellement d'achat)",
        "discountcode":"Code de réduction",
        "use":"Utiliser",
        "paymentmethod":"Mode de paiement",
        "pleasechoose":"s'il vous plaît choisir",
        "billingdetails":"Détails de facturation",
        "payaddress":"Informations sur l'adresse de paiement",
        "firstname":"Prénom",
        "lastname":"Nom de famille",
        "emailaddress":"Adresse email",
        "phone":"Téléphone",
        "country":"Pays / Région",
        "state":"État",
        "city":"Ville",
        "address":"Adresse de la rue",
        "housenumber":"Numéro de maison et nom de rue",
        "suiteunit":"Appartement, suite, unité, etc. (facultatif)",
        "zipcode":"Code postal",
        "submit":"Soumettre",
        "creditcard":"Carte de crédit",
        "cardnumber":"Numéro de carte",
        "expirydate":"Date d'expiration",
        "nameoncard":"Nom sur la carte",
        "savethiscard":"Conservez cette carte pour vos futurs paiements",
        "play":"Jouer",
        "emailerror":"E-mail ERREUR",
        "payresluttitle":"Paiement réussi",

        "pleaseenter":"s'il vous plaît entrer",
        "selectamonth":"Veuillez sélectionner un mois",
        "selectthenumber":"Veuillez sélectionner le nombre d'écrans",
        "selectthepurchase":"Veuillez sélectionner le type d'achat",
        "selectthepayment":"Veuillez sélectionner le mode de paiement",
        "discountcodenotempty":"Le code de réduction ne peut pas être vide !",
        "detail1":"<p>✅ Abonnement stable et renouvelable</p>\n" +
            "<p>✅ Enregistrement de visionnage indépendant haute définition 4K+HDR</p>\n" +
            "<p>✅ Livraison en temps réel</p>\n" +
            "<p>✅ Prise en charge des terminaux mobiles, PC, TV, boîtier TV</p>\n"+
            "<p>✅ Assistance après-vente, remboursement sans souci</p>",
        "detail2":"<p>✅ Abonnement stable, forfait prépayé de 12 mois</p>\n" +
            "<p>✅ Abonnement partagé officiel</p>\n" +
            "<p>✅ Livraison en temps réel</p>\n" +
            "<p>✅ Assistance après-vente, remboursement sans souci</p>",
        "detail3":"<p>✅ Abonnement stable, forfait prépayé de 12 mois</p>\n" +
            "<p>✅ Cclassification du contenu : 18+/R18(21)/TV-MA</p>\n" +
            "<p>✅ Livraison en temps réel</p>\n" +
            "<p>✅ Prise en charge de plusieurs appareils : téléphone portable, ordinateur, téléviseur</p>\n" +
            "<p>✅ Assistance après-vente, remboursement sans souci</p>",
        "detail5":"<p>✅ Utilisez votre abonnement YouTube personnel pour rejoindre un groupe familial premium</p>\n" +
            "<p>✅ Envoyer un lien d'invitation</p>\n" +
            "<p>✅ Conservez vos listes et vos abonnements</p>\n" +
            "<p>✅ Assistance après-vente, remboursement sans souci</p>\n"+
            "<p>✅ Premium ne prend en charge qu'une seule personne, vous ne pouvez pas inviter vos proches ou amis</p>\n"
        // "<p>✅ Help: Before purchasing, check play.google.com if your account country is ES, FR, PL, IT, DE, NL, GB, CA, US, JP, GR, CH, LT, PA, SG , CZ, SE, PT, BE, IL.</p>"
        ,
        "detail7":"<p>✅ 6 personnes partagent 1 abonnement</p>\n" +
            "<p>✅ ChatGPT Plus 4.0</p>\n" +
            "<p>✅ Profitez des GPT</p>\n" +
            "<p>✅ Disponible pour les plugins</p>\n" +
            "<p>✅ Interdire l'API</p>\n" +
            "<p>✅ Remboursement sans souci dans les 24 heures</p>",

        "detail8":"<p>✅ 4 personnes partagent 1 abonnement</p>\n" +
            "<p>✅ Livraison en temps réel</p>\n" +
            "<p>✅ Prise en charge des terminaux mobiles, PC, TV, boîtier TV</p>\n" +
            "<p>✅ Assistance après-vente, remboursement sans souci</p>",
        "detail10":"<p>✅ Forfait Premium, 3,88 $/mois/écran</p>\n" +
            "<p>✅ Envoyer un lien d'invitation en temps réel</p>\n" +
            "<p>✅ Abonnement privé, gardez tout</p>\n" +
            "<p>✅ Vos favoris et vos listes</p>\n" +
            "<p>✅ Garantie, garantie de remboursement</p>",

        "title":"Veuillez confirmer les informations sur le produit",
        "price":"Prix",
        "product":"Nom du produit",
        "note":"NOTE",
        "notecontent":"Tous nos abonnements sont partagés et vendus sous forme de sous-comptes, assurez-vous de bien comprendre cela avant d'acheter.",
        "notegpt":"Il s'agit d'un compte partagé avec 6 personnes, et la politique officielle est de poser 40 questions en 3 heures. Si la limite de questions est atteinte, il est recommandé d'attendre un certain temps avant de poser à nouveau la question. NFXBUS fournit le GPT Plus officiel. La vitesse de génération du GPT est liée à votre vitesse Internet.",
        "notenetflix":"Si vous avez acheté un abonnement Netflix Premium, veuillez vérifier votre numéro de compte, votre mot de passe et suivre les instructions pour utiliser l'emplacement correct sur la page Abonnements. Une fois que vous êtes à votre emplacement, définissez un code PIN pour verrouiller vos informations afin d'empêcher d'autres personnes de les utiliser. Si le mot de passe change, veuillez vous connecter à la page d'abonnement NFXBUS pour afficher le nouveau mot de passe du compte.",
        "notespotify":"Si vous avez acheté un abonnement Spotify Premium, veuillez vérifier votre numéro de compte et votre mot de passe sur la page « Abonnement »",
        "notedisney":"Si vous avez acheté un abonnement Disney+ Premium, veuillez vérifier votre numéro de compte, votre mot de passe et suivez les instructions pour utiliser l'emplacement correct sur la page « Abonnement ». Une fois que vous êtes à votre emplacement, définissez un code PIN pour verrouiller vos informations afin d'empêcher d'autres personnes de les utiliser. Si le mot de passe change, veuillez vous connecter à la page d'abonnement NFXBUS pour afficher le nouveau mot de passe du compte.",
        "noteyoutube":"Si vous avez acheté YouTube Premium, veuillez saisir votre adresse e-mail de connexion YouTube sur la page « Abonnement ». Après avoir soumis votre adresse e-mail, le personnel vous enverra un lien d'invitation au groupe familial YouTube Premium par e-mail. Veuillez vérifier votre adresse e-mail et cliquer pour accepter.",
    },
    login:{
        "welcomelogin":"Bienvenue à la connexion",
        "login":"Se connecter",
        "updatepwd":"Changer le mot de passe",
        "reg":"Compte enregistré",
        "agreement":", vous acceptez nos conditions d'utilisation et confirmez avoir lu notre politique de confidentialité",
        "quicklogin":"Connexion rapide",
        "sendcode":"Envoyer le code",
        "register":"Registre",
        "changepassword":"Changer le mot de passe",
        "verificationcode":"Le code de vérification",
        "name":"Nom",
        "password":"Mot de passe",
        "agapassword":"Répéter le mot de passe",
        "modify":"Modifier",

        "validatepswlen":"Le mot de passe contient des lettres majuscules et minuscules, des chiffres et des caractères spéciaux, n'importe quelle combinaison de trois d'entre eux, et la longueur est comprise entre 8 et 16 !",
        "checkagreement":"Veuillez vérifier l'accord",
        "passwordinconsistency":"Les mots de passe saisis deux fois sont incohérents !",
        "passwordagain":"Veuillez saisir à nouveau le mot de passe",
        "verificationsend":"Le code de vérification a été envoyé, faites attention à vérifier",
    },
    luckdraw:{
        "lotteryticket":"Billet de loterie",
        "integral":"intégral",
        "welcome":"Bienvenue sur nfxbus, ceci est notre page d'activité, cliquez sur la carte pour tirer des prix en un clic,\n" +
            " les récompenses incluent divers packages de comptes gratuits, des codes de réduction et d'autres remises,\n" +
            "  merci pour votre soutien et votre confiance.",
        "none":"sans prix",
        "redemptioncode":"Utiliser le code",
        "copy":"copie",
        "prizelist":"Liste des prix",
        "notused":"non utilisé",
        "used":"Utilisé",
        "expired":"Expiré",

        "prizeno":"Prix n°",
        "contents":"Contenu",
        "exchangedate":"Date d'échange",
        "createtime":"créer du temps",
        "exchange":"échange",
        "insufficientpoints":"Points insuffisants",
        "consumepoints":"Cette opération va consommer 10 points. Continuer ?",
        "tips":"Conseils",
        "comfirm":"confirmer",
        "cancel":"Annuler",
        "failtowin":"Ne pas gagner à la loterie",
        "congratulations":"Félicitations pour l'obtention",
        "copysucceeded":"Copié avec succès !",
        "copyfailed":"La copie a échoué !",

    },
    subscribe:{
        "renewnow":"Renouveler maintenant",
        "subscription":"Renouveler le même abonnement sans changer",
        "independent":"4K+HDR HD, enregistrement de visionnage indépendant",
        "guarantee":"Garantie de remboursement",
        "timeleft":"Temps restant",
        "openingtime":"Heure d'ouverture du compte",
        "endtime":"Fin des temps",
        "account":"Compte",
        "password":"Mot de passe",
        "profile":"Profil",
        "pleaseuse":"s'il vous plaît utiliser",
        "subaccount":"sous-compte",
        "myprofile":"N'occupez pas la place de quelqu'un d'autre !",
        "renew":"Renouveler",
        "aftersales":"Après-vente",

        "yourownsub":"1.Veuillez utiliser votre propre sous-compte en fonction du numéro de série",
        "delete":"2.Ne pas modifier/supprimer d'autres sous-comptes",
        "modifysubscription":"3.Ne pas modifier les informations relatives à l'abonnement",
        "reminder":"Rappel : si vous rencontrez des problèmes, veuillez contacter le service client à temps, achetez un abonnement sur nfxbus, profitez du service client VIP et n'avez pas besoin de changer de numéro pour le renouvellement.",

        "youtubeloginemail":"Veuillez saisir votre adresse e-mail de connexion YouTube. Vous devez vous inscrire à un groupe résidentiel en utilisant l'adresse e-mail de connexion, sinon vous serez expulsé du groupe. Si vous avez besoin d'aide, veuillez contacter le service client de Nfxbus par e-mail.",
        "inspectaddress":"Veuillez vérifier le pays/la région de votre compte : KR, ES, FR, PL, IT, DE, NL, GB, CA, US, JP, GR, CH, LT, PA, SG, CZ, SE, PT, BE, Illinois. Si vous rencontrez des problèmes, veuillez contacter les employés via des tickets dans le délai imparti.",
        "aftersalesservice":"Service après-vente",
        "support":"page d'assistance.",
        "submit":"Soumettre",

        "buyit":"Il n'y a pas encore d'abonnement, dépêchez-vous de l'acheter",
        "paymentsucceeded":"Paiement réussi",
        "month":"Veuillez sélectionner un mois",
        "number":"Veuillez sélectionner le nombre d'écrans",
        "purchase":"Veuillez sélectionner le type d'achat",
        "payment":"Veuillez sélectionner le mode de paiement",
        "name":"Veuillez saisir le nom sur la carte",
        "card":"Veuillez saisir le numéro de carte",
        "cvv":"Veuillez saisir le CVV",
        "expirydate":"Veuillez saisir la date d'expiration",
        "processing":"Le statut de traitement a été soumis. Vérifiez le statut de traitement sur le bon de travail.",
        "cannotempty":"Le code de réduction ne peut pas être vide !",
        "remind":"S'il faut envoyer un message",
        "originalpassword":"Le mot de passe d'origine pour cette opération ne sera plus utilisé, faut-il continuer ?",
        "passwordmeets":"Le mot de passe contient des lettres majuscules et minuscules, des chiffres et des caractères spéciaux, n'importe quelle combinaison de trois d'entre eux, et la longueur est comprise entre 8 et 16 !",
        "pwdupdatetime":"Changement de mot de passe",
        "pwdupdatetips":"Soumis, veuillez patienter",

        "getverlink":"Obtenir le lien de vérification de connexion",
        "ignorefeature":"Si vous n'avez pas été invité à remplir le code de vérification, veuillez ignorer cette fonctionnalité.",
        "openlink":"Ouvrir le lien",
        "clicklink":"Cliquez pour obtenir le lien",
        "getGptLoginCode":"Obtenir le code de connexion GPT",
        "ignorecode":"Si vous n'avez pas besoin d'obtenir le code de connexion, veuillez l'ignorer.",
        "getcode":"Cliquez pour obtenir le code",
    },
    userinfo:{
        "signout":"se déconnecter",
        "nickname":"Surnom",
        "email":"E-mail",
        "code":"Code",
        "vercode":"Le code de vérification",
        "avatar":"Avatar",
        "uploadavatar":"Veuillez télécharger votre avatar",
        "logout":"Etes-vous sûr de vouloir vous déconnecter ?",
        "onlyjpgpng":"Les images téléchargées ne peuvent être qu'au format JPG | png",
        "cannotexceed":"La taille de l'image téléchargée ne peut pas dépasser 5 Mo !"
    },
    wish:{
        "wish":"Souhait",
        "help":"Nfxbus choisira quelques souhaits pour aider",
        "christmas":"qu'ils se réalisent quand Noël arrive, laissez votre souhait",
        "submit":"Soumettre",
        "mywish":"mon souhait"
    },
    map:{
        "home":"Maison",
        "userinfo":"informations utilisateur",
        "login":"Se connecter",
        "withfriends":"Avec des amis",
        "subscribe":"S'abonner",
        "aftersales":"Après-vente",
        "luckdraw":"Tirage au sort",
        "wish":"Souhait",
        "helpcenter":"Centre d'aide",
        "tradingstamps":"timbres commerciaux",
    },
}

export default fr
