<template>
    <div class="flex-col" style="background: #161B22;width: 100%;padding-top: 50px;">
        <div class="justify-center">
            <a-row style="width: 70%;">
                <a-col :xs="24" :sm="24" :md="24" :lg="12" :xl="6" justify="center">
                    <div class="flex-col align-center" style="margin-top: 20px;">
                        <img src="../../assets/logo.png" style="width:80px;height: 80px;"/>
                        <span class="food-icon-text">{{$t('footer.contactus')}}</span>
                        <div class="food-icon-Contact flex-row justify-between">
<!--                            <div class="food-icon-fs flex-col"></div>-->
<!--                            <div class="food-icon-dy flex-col"></div>-->
<!--                            <div class="food-icon-twt flex-col"></div>-->
                            <div class="food-icon-chat flex-col" @click="chatshow"></div>
                            <!-- <a href="mailto:nfxbus@gmail.com"><div class="food-icon-email flex-col"></div></a>-->
                        </div>
                    </div>
                </a-col>
                <a-col :xs="24" :sm="24" :md="24" :lg="12" :xl="6" justify="center">
                    <div class="flex-col align-center">
                        <span class="navgtitle">{{$t('footer.navigation')}}</span>
                        <router-link to="home" class="navgtext">{{$t('footer.home')}}</router-link>
<!--                        <router-link to="friends" class="navgtext">{{$t('footer.friends')}}</router-link>-->
                        <router-link to="subscribe" class="navgtext">{{$t('footer.subscribe')}}</router-link>
                        <router-link to="aftersales" class="navgtext">{{$t('footer.aftersales')}}</router-link>
                        <router-link to="friends" class="navgtext">{{$t('footer.friends')}}</router-link>
                    </div>
                </a-col>
                <a-col :xs="24" :sm="24" :md="24" :lg="12" :xl="6" justify="center">
                    <div class="flex-col align-center">
                        <span class="navgtitle">{{$t('footer.help')}}</span>
                        <router-link to="helpdetail?id=9" class="navgtext">{{$t('footer.termsofuse')}}</router-link>
                        <router-link to="helpdetail?id=10" class="navgtext">{{$t('footer.refundpolicy')}}</router-link>
                        <router-link to="helpdetail?id=11" class="navgtext">{{$t('footer.privacypolicy')}}</router-link>
                        <a href="https://help.nfxbus.com/" target="_blank" class="navgtext">{{$t('footer.helpcenter')}}</a>
                    </div>
                </a-col>
                <a-col :xs="24" :sm="24" :md="24" :lg="12" :xl="6" justify="center">
                    <div class="flex-col align-center">
                        <span class="navgtitle">{{$t('footer.language')}}</span>
                        <a-select @change="handleChange" style="width: 120px;margin-top: 20px;" v-model="language">
                            <a-select-option value="en"> EngLish </a-select-option>
                            <a-select-option value="kr"> 한국어 </a-select-option>
                            <a-select-option value="ar"> عربي </a-select-option>
                            <a-select-option value="es"> español </a-select-option>
                            <a-select-option value="fr"> Français </a-select-option>
                            <a-select-option value="pt"> Português </a-select-option>
                            <a-select-option value="it"> Italiano </a-select-option>
                        </a-select>
                    </div>
                </a-col>
            </a-row>
        </div>
        <div style="padding: 30px;" class="justify-center">
            <a-row class="paytype">
                <a-col :xs="7" :sm="7" :md="7" :lg="7" :xl="2" :offset="1" justify="center">
                    <img src="https://nfxbus.oss-us-west-1.aliyuncs.com/frontend/paytype/visa.jpg" class="payimg">
                </a-col>
                <a-col :xs="7" :sm="7" :md="7" :lg="7" :xl="2" :offset="1" justify="center">
                    <img src="https://nfxbus.oss-us-west-1.aliyuncs.com/frontend/paytype/master.jpg" class="payimg">
                </a-col>
                <a-col :xs="7" :sm="7" :md="7" :lg="7" :xl="2" :offset="1"  justify="center">
                    <img src="https://nfxbus.oss-us-west-1.aliyuncs.com/frontend/paytype/jcb.jpg" class="payimg">
                </a-col>
                <a-col :xs="7" :sm="7" :md="7" :lg="7" :xl="2" :offset="1"  justify="center">
                    <img src="https://nfxbus.oss-us-west-1.aliyuncs.com/frontend/paytype/paypal.jpg" class="payimg">
                </a-col>
                <a-col :xs="7" :sm="7" :md="7" :lg="7" :xl="2" :offset="1"  justify="center">
                    <img src="https://nfxbus.oss-us-west-1.aliyuncs.com/frontend/paytype/pay.jpg" class="payimg">
                </a-col>
                <a-col :xs="7" :sm="7" :md="7" :lg="7" :xl="2" :offset="1"  justify="center">
                    <img src="https://nfxbus.oss-us-west-1.aliyuncs.com/frontend/paytype/payco.jpg" class="payimg">
                </a-col>
                <a-col :xs="7" :sm="7" :md="7" :lg="7" :xl="2" :offset="1"  justify="center">
                    <img src="https://nfxbus.oss-us-west-1.aliyuncs.com/frontend/paytype/card.jpg" class="payimg">
                </a-col>
                <a-col :xs="7" :sm="7" :md="7" :lg="7" :xl="2" :offset="1"  justify="center">
                    <img src="https://nfxbus.oss-us-west-1.aliyuncs.com/frontend/paytype/samsung.jpg" class="payimg">
                </a-col>
            </a-row>
        </div>
        <div class="flex-col justify-center">
            <div class="justify-center copytxt">
                <p>Copyright &copy; Cs Wishfulfill  Information Technology Co., Ltd.</p>
            </div>
<!--            <div class="justify-center copytxt" style="width: 100%">-->
<!--                <a-row class="companyaddress">-->
<!--                    <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">-->
<!--                        <p style="display: flex;flex-wrap: wrap">(Room 1318-A269, Lot 5, Oaks Colorful Plaza, No. 858 Dujuan Road, Wangyue Street, Yuelu District, Yuelu District, Changsha City, Hunan Province)</p>-->
<!--                    </a-col>-->
<!--                </a-row>-->
<!--            </div>-->
<!--            <div class="justify-center copytxt">-->
<!--                <p>E-mail consultation: nflxbus@gmail.com</p>-->
<!--            </div>-->
        </div>
    </div>
</template>

<script>
    import { Crisp } from "crisp-sdk-web";
    import Bus from "@/util/Bus";
    export default {
        name: "footerinfo",
        data(){
            return{
                language:"",
            }
        },
        created() {
            this.language=localStorage.lang
        },
        methods:{
            handleChange(value) {
                Bus.$emit('switchLanguage', value)
                this.reload()
            },
            chatshow(){
                Crisp.chat.open();
            }
        },
        inject: ['reload'],
    }
</script>

<style scoped>

    .copytxt{
        font-weight: bolder;
        color: rgb(62,77,83);
        font-size: 12px;
    }

    .food-icon-text {
        overflow-wrap: break-word;
        color: rgba(255, 255, 255, 1);
        font-size: 18px;
        font-family: Source Han Sans CN-Medium;
        white-space: nowrap;
        line-height: 22px;
        margin-top: 10px;
    }
    .food-icon-Contact {
        height: 25px;
        margin-top: 16px;
    }
    .food-icon-chat {
        width: 24px;
        height: 24px;
        background: url(https://nfxbus.oss-us-west-1.aliyuncs.com/frontend/chat.png) 100% no-repeat;
        background-size: 100% 100%;
        margin-top: 1px;
        cursor: pointer;
    }

    .food-icon-fs {
        width: 24px;
        height: 24px;
        background: url(https://nfxbus.oss-us-west-1.aliyuncs.com/frontend/facebook.png) 100% no-repeat;
        background-size: 100% 100%;
        margin-top: 1px;
    }

    .food-icon-dy {
        width: 24px;
        height: 24px;
        background: url(https://nfxbus.oss-us-west-1.aliyuncs.com/frontend/douyin.png) 100% no-repeat;
        background-size: 100% 100%;
        margin-left: 16px;
    }

    .food-icon-twt {
        width: 24px;
        height: 24px;
        background: url(https://nfxbus.oss-us-west-1.aliyuncs.com/frontend/twitter.png) 100% no-repeat;
        background-size: 100% 100%;
        margin-left: 16px;
    }
    .food-icon-email {
        width: 24px;
        height: 24px;
        background: url(https://nfxbus.oss-us-west-1.aliyuncs.com/frontend/email.png) 100% no-repeat;
        background-size: 100% 100%;
        margin-left: 16px;
    }

    .navgtitle{
        margin-top: 30px;
        overflow-wrap: break-word;
        color: rgba(255, 255, 255, 1);
        font-size: 18px;
        font-family: Source Han Sans CN-Medium;
        text-align: left;
        white-space: nowrap;
        line-height: 22px;
    }
    .navgtext{
        overflow-wrap: break-word;
        color: rgba(194, 194, 194, 1);
        font-size: 16px;
        font-family: Source Han Sans CN-Normal;
        text-align: left;
        white-space: nowrap;
        line-height: 19px;
        margin-top: 10px;
    }
    .paytype{
        width: 65%;
    }

    .payimg{
        width: 80px;
        height: 50px;
        border-radius: 10px;
    }
    .companyaddress{
        width: 56%;
    }

    @media screen and (max-width: 768px){
        .paytype{
            width: 100%;
        }
        .payimg{
            width: 70px;
            height: 40px;
            border-radius: 10px;
            margin-top: 10px;
        }
        .companyaddress{
            width: 80%;
        }
        .copytxt{
            font-size: 8px;
        }
    }

</style>
