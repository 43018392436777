const en = {
    aftersales:{
        "contactus":"Contact us for quick response",
        "workorder":"Work order list",
        "createjob":"Create a job",
        "submittinworkorder":"When submitting a work order, please include as much detailed information as possible, such as error screenshots, account information, etc., which are very important for us to deal with problems quickly.",
        "workordercontent":"Work order Content",
        "workorderreply":"Work order Reply",
        "subscriptionaccount":"Subscription account",
        "problemtype":"Problem type",
        "tobeprocessed":"To be processed",
        "reply":"reply",
        "processed":"Processed,View replies",
        "revoked":"Revoked",
        "create":"Create",
        "workorderno":"Work order No",
        "product":"Product",
        "status":"Status",
        "type":"Type",
        "ticket":"Ticket",
        "createtime":"createTime",
    },
    excode:{
        "redemptioncode":"Redeem Code",
        "pleaseredemptioncode":"Please enter the Redeem Code",
        "exchange":"Use",
        "notused":"not used",
        "used":"Used",
        "expired":"Expired",
        "status":"Status",
        "exchangetime":"Exchange date",
        "remark":"Remark",
        "createtime":"createTime",
    },
    footer:{
        "contactus":"contact us",
        "navigation":"Navigation",
        "home":"Home",
        "friends":"Friends",
        "subscribe":"Subscribe",
        "aftersales":"AfterSales",
        "help":"Help",
        "termsofuse":"Terms of Use",
        "refundpolicy":"Refund Policy",
        "privacypolicy":"Privacy Policy",
        "helpcenter":"Help center",
        "paymentmethod":"Payment method",
        "language":"Language",
    },
    friends:{
        "promotioninfo":"Promotion information",
        "currentlevel":"current level",
        "content":"You can use exclusive Invitation link and discount codes to share with others to earn commissions. The higher the level, the higher the commission share",
        "rolemembers":"The role of members",
        "promotionallinks":"Invitation link",
        "discountcodes":"Discount Codes",
        "copy":"copy",
        "rebateratio":"Commission rate",
        "discountratio":"Discount ratio",
        "lowestdiscountratio":"User subscription discount",
        "walletsandpoints":"Wallets and Freeze amount",
        "applywithdrawal":"After you apply for withdrawal, we will review and make payment within 7 working days",
        "withdraw":"Withdraw",
        "record":"Record",
        "integral":"Integral",
        "wallet":"Wallets",
        "freezeamount":"Freeze",
        "pointsused":"YouTube products frozen for 3 days, other products frozen for 1 day",
        "orderdetails":"Order Details",
        "orderamount":"Order amount",
        "earnings":"Earnings",
        "withdrawalsrecord":"Withdrawals record",
        "accounts":"Accounts",
        "accounttype":"Account type",
        "withdrawalamount":"Amount",
        "cancel":"Cancel",
        "submit":"Submit",

        "greaterthan":"Amount must be greater than 0!",
        "normal":"normal",
        "invalid":"invalid",
        "freeze":"Freeze",
        "withdrawing":"Withdrawing",
        "extracted":"Extracted",

        "bankcard":"bank card",
        "promotionlink":"Promotion link",
        "discountcode":"Discount code",
        "waitingforapproval":"Waiting for approval",
        "waitingforpayment":"Waiting for payment",
        "notpass":"not pass",
        "paid":"Paid",

        "cashwithdrawalid":"Cash withdrawal ID",
        "accountsreceivable":"Accounts receivable",
        "amount":"Amount(USD)",
        "status":"status",
        "applicationtime":"Application time",
        "processingresults":"Processing results",

        "orderid":"order ID",
        "useramount":"User Amount",
        "duration":"duration",
        "promotiontype":"promotion type",
        "createtime":"createTime",
        "eainings":"Eainings(USD)",

        "receivingaccount":"receiving account",
        "nowithdrawableamount":"Minimum withdrawal of 30 USD",
        "copysuccessfully":"Copy successfully",
        "copyfailed":"Copy failed",
        "activetime":"Activetime",
    },
    home:{
        'join':'Join',
        "texttitle":"Shared premium subscription with lower price on",
        "buynow":"PURCHASE NOW",
        "soldout":"INVENTORY SHORTAGE",
        "shoptitle":"After placing the order, check the email message of the current login email (please ensure that the email address is correct)",
        "delivery":"REAL-TIME DELIVERY",
        "after":"Real-time delivery after payment without waiting",
        "certificate":"SSl CERTIFICATE",
        "payments":"Payments take place in a secure environment with an SSL security certificate",
        "onlinesupport":"24/7 LIVE SUPPORT",
        "privatecustomer":"Nfxbus provides 24/7 online private customer service",
        "subscriber":"SUITABLE CO-SUBSCRIBER",
        "subscriptions":"Share premium subscription at lower price. Priority matches for co-subscribers from the same country",
        "moneyback":"REFUND GUARANTEE",
        "refund":"You have 1-3 days to request a refund(YouTube 3 Days Other Products 24 Hours)",
        "pwdreset":"QUICK RESET PWD",
        "pwdresetdesc":"PWD not works? Click reset pwd on the subscription page without waiting and manual operation",
        "parkingspace":"parking space",
        "gift":"gift",
        "screen":"Screen",
        "1screen":"1 profile(Shared)",
        "maxnum":"profiles(Private)",
        "month":"Month",
        "buytyp":"Buy Type",
        "subscription":"subscription",
        "voucher":"Voucher(Renewal purchase)",
        "discountcode":"Discount code",
        "use":"Use",
        "paymentmethod":"Payment method",
        "pleasechoose":"please choose",
        "billingdetails":"Billing Details",
        "payaddress":"Payment Address Information",
        "firstname":"First name",
        "lastname":"Last name",
        "emailaddress":"Email address",
        "phone":"Phone",
        "country":"Country / Region",
        "state":"state",
        "city":"Town / City",
        "address":"Street address",
        "housenumber":"House number and street name",
        "suiteunit":"Apartment, suite, unit, etc. (optional)",
        "zipcode":"ZIP Code",
        "submit":"Submit",
        "creditcard":"Credit Card",
        "cardnumber":"Card number",
        "expirydate":"Expiry date",
        "nameoncard":"Name on card",
        "savethiscard":"Save this card for future payments",
        "play":"Play",
        "emailerror":"Email ERROR",
        "payresluttitle":"Payment succeeded",

        "pleaseenter":"please enter",
        "selectamonth":"Please select a month",
        "selectthenumber":"Please select the number of screens",
        "selectthepurchase":"Please select the purchase type",
        "selectthepayment":"Please select the payment method",
        "discountcodenotempty":"Discount code cannot be empty!",
        "detail1":"<p>✅ Stable and renewable subscription</p>\n" +
            "<p>✅ 4K+HDR high-definition, independent viewing record</p>\n" +
            "<p>✅ Real-time delivery</p>\n" +
            "<p>✅ Support mobile terminal, PC, TV, TV box</p>\n"+
            "<p>✅ After-sales support, worry-free refund</p>",
        "detail2":"<p>✅ Stable subscription, prepaid 12-month fee</p>\n" +
            "<p>✅ Official shared subscription</p>\n" +
            "<p>✅ Real-time delivery</p>\n" +
            "<p>✅ After-sales support, worry-free refund</p>",
        "detail3":"<p>✅ Stable subscription, prepaid 12-month fee</p>\n" +
            "<p>✅ Content ratings: 18+/R18(21)/TV-MA</p>\n" +
            "<p>✅ Real-time delivery</p>\n" +
            "<p>✅ Support multiple devices: mobile phone, computer, TV</p>\n" +
            "<p>✅ After-sales support, worry-free refund</p>",
        "detail5":"<p>✅ Use your personal YouTube subscription to join premium family group</p>\n" +
            "<p>✅ Send invitation link</p>\n" +
            "<p>✅ Keep your lists and subscriptions</p>\n" +
            "<p>✅ After-sales support, worry-free refund</p>\n"+
            "<p>✅ Premium only supports 1 person, you cannot invite your relatives or friends</p>\n"
        // "<p>✅ Help: Before purchasing, check play.google.com if your account country is ES, FR, PL, IT, DE, NL, GB, CA, US, JP, GR, CH, LT, PA, SG , CZ, SE, PT, BE, IL.</p>"
        ,
        "detail7":"<p>✅ 6 people share 1 subscription</p>\n" +
            "<p>✅ ChatGPT Plus 4.0</p>\n" +
            "<p>✅ Enjoy GPTs</p>\n" +
            "<p>✅ Available For Plugins</p>\n" +
            "<p>✅ Ban API</p>\n" +
            "<p>✅ Worry free refund within 24 hours</p>",

        "detail8":"<p>✅ 4 people share 1 subscription</p>\n" +
            "<p>✅ Real-time delivery</p>\n" +
            "<p>✅ Support mobile terminal, PC, TV, TV box</p>\n" +
            "<p>✅ After-sales support, worry-free refund</p>",
        "detail10":"<p>✅ Premium plan, $3.88/month/screen</p>\n" +
            "<p>✅ Send invitation link in real time</p>\n" +
            "<p>✅ Private subscription, keep all</p>\n" +
            "<p>✅ Your favorites and lists</p>\n" +
            "<p>✅ Warranty, refunds guarantee</p>",

        "title":"Please confirm product information",
        "price":"Price",
        "product":"Product name",
        "note":"NOTE",
        "notecontent":"All of our subscriptions are shared and sold as sub-accounts, please make sure you understand this before purchasing.",
        "notegpt":"This is a shared account with 6 people, and the official policy is to ask 40 questions within 3 hours. If the question limit is reached, it is recommended to wait for a period of time to ask again. NFXBUS provides the official GPT Plus. The GPT generation speed is related to your internet speed.",
        "notenetflix":"If you have purchased a Netflix Premium membership, please review your account number, password, and follow the prompts to use the correct location on the Subscriptions page. Once you're in your location, set a pin to lock your information to prevent others from using it. If the password changes, please log in to the NFXBUS subscription page to view the new account password.",
        "notespotify":"If you have purchased Spotify Premium membership, please check your account number and password on the \"Subscription\" page",
        "notedisney":"If you purchased a Disney+ premium membership, please check your account number, password, and follow the prompts to use the correct location on the \"Subscription\" page. Once you're in your location, set a pin to lock your information to prevent others from using it. If the password changes, please log in to the NFXBUS subscription page to view the new account password.",
        "noteyoutube":"If you purchased YouTube Premium, please submit your YouTube login email on the \"Subscription\" page. After submission, the staff will send a YouTube Premium family group invitation link to your email. Please check your email address and click to accept.",
    },
    login:{
        "welcomelogin":"Welcome to Login",
        "login":"Login",
        "updatepwd":"Change Password",
        "reg":"Registered Account",
        "agreement":", you agree to our Terms of User and confirm that you have read our Privacy Policy",
        "quicklogin":"Quick Login",
        "sendcode":"Send Code",
        "register":"Register",
        "changepassword":"Change Password",
        "verificationcode":"Verification Code",
        "name":"Name",
        "password":"Password",
        "agapassword":"Repeat Password",
        "modify":"Modify",
        "validatepswlen":"The password meets uppercase and lowercase letters, numbers and special characters, any combination of three of them, and the length is between 8 and 16!",
        "checkagreement":"Please check the agreement",
        "passwordinconsistency":"The passwords entered twice are inconsistent!",
        "passwordagain":"please enter password again",
        "verificationsend":"The verification code has been sent, pay attention to check",
    },
    luckdraw:{
        "lotteryticket":"Lottery ticket",
        "integral":"integral",
        "welcome":"Welcome to nfxbus, this is our activity page, click on the card to draw prizes with one click,\n" +
            "                                    the awards include various free account packages,\n" +
            "                                    discount codes and other discounts, thank you for your support and trust.",
        "none":"prizeless",
        "redemptioncode":"Redeem Code",
        "copy":"copy",
        "prizelist":"Prize list",
        "notused":"not used",
        "used":"Used",
        "expired":"Expired",

        "prizeno":"Prize No",
        "contents":"Contents",
        "exchangedate":"Exchange date",
        "createtime":"createTime",
        "exchange":"exchange",
        "insufficientpoints":"Insufficient points",
        "consumepoints":"This operation will consume 10 points. Continue?",
        "tips":"Tips",
        "comfirm":"comfirm",
        "cancel":"cancel",
        "failtowin":"Not winning the lottery",
        "congratulations":"Congratulations on obtaining",
        "copysucceeded":"Copied successfully!",
        "copyfailed":"Copied failed!",

    },
    subscribe:{
        "renewnow":"Renew now",
        "subscription":"Renew the same subscription without changing",
        "independent":"4K+HDR HD, independent viewing record",
        "guarantee":"money-back guarantee",
        "timeleft":"Time left",
        "openingtime":"Account opening time",
        "endtime":"End Time",
        "account":"Account",
        "password":"Password",
        "profile":"Profile",
        "pleaseuse":"please use",
        "subaccount":"sub account",
        "myprofile":"Don't occupy someone else's position!",
        "renew":"Renew",
        "aftersales":"After sales",

        "yourownsub":"1.Please use your own sub account according to the serial number",
        "delete":"2.Do not modify/delete other sub-accounts",
        "modifysubscription":"3.Do not modify subscription-related information",
        "reminder":"Reminder: If you encounter any problems, please contact customer service in time, buy a subscription at nfxbus, enjoy VIP customer service, and do not need to change the number for renewal.",

        "youtubeloginemail":"Please enter your YouTube login email. You must register for a homegroup using the login email address, otherwise you will be kicked out of the home. If you need assistance, please contact Nfxbus customer service via email.",
        "inspectaddress":"Please check your account country/region: KR, ES, FR, PL, IT, DE, NL, GB, CA, US, JP, GR, CH, LT, PA, SG, CZ, SE, PT, BE, Illinois. If you encounter any problems, please contact the employees through tickets within the designated time.",
        "aftersalesservice":"after sales service",
        "support":"support page.",
        "submit":"Submit",

        "buyit":"There are no subscriptions yet, please hurry to buy it",
        "paymentsucceeded":"Payment succeeded",
        "month":"Please select a month",
        "number":"Please select the number of screens",
        "purchase":"Please select the purchase type",
        "payment":"Please select the payment method",
        "name":"Please enter Name on card",
        "card":"Please enter Card number",
        "cvv":"Please enter CVV",
        "expirydate":"Please enter Expiry date",
        "processing":"Processing status has been submitted. Check the processing status on the work order.",
        "cannotempty":"Discount code cannot be empty!",
        "remind":"Whether to send message",
        "originalpassword":"The original password for this operation will no longer be used, whether to continue?",
        "passwordmeets":"The password meets uppercase and lowercase letters, numbers and special characters, any combination of three of them, and the length is between 8 and 16!",
        "pwdupdatetime":"Password change",
        "pwdupdatetips":"Submitted, please wait patiently",

        "getverlink":"Get login Verification link",
        "ignorefeature":"If you hava not been asked to fill in the verification code,please ignore this feature.",
        "getGptLoginCode":"Get GPT login code",
        "ignorecode":"If you don't need to get the login code, please ignore it.",
        "getcode":"Click to get code",
        "openlink":"Open the link",
        "clicklink":"Click to get link",
    },
    userinfo:{
        "signout":"Sign out",
        "nickname":"Nickname",
        "email":"Email",
        "code":"Code",
        "vercode":"Verification code",
        "avatar":"Avatar",
        "uploadavatar":"Please upload your avatar",
        "logout":"Are you sure you want to log out?",
        "onlyjpgpng":"Uploaded pictures can only be in JPG | png format",
        "cannotexceed":"The size of the uploaded image cannot exceed 5MB!"
    },
    wish:{
        "wish":"Wish",
        "help":"Nfxbus will choose some wishes to help",
        "christmas":"them come true when Christmas comes, leave your wish",
        "submit":"Submit",
        "mywish":"my wish"
    },
    map:{
        "home":"Home",
        "userinfo":"User info",
        "login":"Login",
        "withfriends":"With friends",
        "subscribe":"Subscribe",
        "aftersales":"After Sales",
        "luckdraw":"Luckdraw",
        "wish":"Wish",
        "helpcenter":"Help Center",
        "tradingstamps":"trading stamps",
    },
}

export default en
